import { apiGet } from '~/services/api';
import getRoute from '~/utilities/configs/apiMap';

export const LeaderboardApi = {
  getLeaderboard: (uuid: string) => {
    return apiGet({
      url: getRoute({ endpont: 'get_leaderbord' }),
      headers: {
        Authorization: uuid,
      },
    });
  },
  getLeaderboardInfo: () => {
    return apiGet({
      url: getRoute({ endpont: 'get_leaderbord_info' }),
    });
  },
};