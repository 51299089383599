import type { Leaders, LeaderboardInfo } from '~/types/leaderboard';
import { LeaderboardService } from '~/services/leaderboard.service';

export const useLeaderboardStore = defineStore('liderboard', () => {
  const leaderboardData = ref<Leaders | null>(null);
  const refreshStatus = ref<'before refresh' | 'pending' | 'done'>('pending');
  const showTable = ref(true);
  const isLoaded = ref(false);

  const leaderboardInfo = ref<LeaderboardInfo | null>(null);

  function saveLeaderboardInfo(data: LeaderboardInfo) {
    if (!data) return;
    leaderboardInfo.value = data;
  }

  async function getLeaderboardInfo() {
    try {
      const response: any = await LeaderboardService.getLeaderboardInfo();
      saveLeaderboardInfo(response);
      isLoaded.value = true;
    } catch (e) {
      console.log(e);
    }
  }

  function saveLeaderboardData(data: Leaders) {
    if (!data) return;
    leaderboardData.value = data;
  }

  async function getLeaderboardData() {
    try {
      refreshStatus.value = 'pending';
      const response: any = await LeaderboardService.getLeaderboard();
      saveLeaderboardData(response.leaders);
      if(response.leaders.length <= 3) {
        showTable.value = false;
      } else {
        showTable.value = true;
      }
      setTimeout(() => {
        refreshStatus.value = 'done';
        setTimeout(() => {
          refreshStatus.value = 'before refresh';
        }, 3000);
      }, 1000);
    } catch (e) {
      console.log(e);
    }
  }

  return {
    leaderboardData,
    refreshStatus,
    showTable,
    leaderboardInfo,
    isLoaded,
    saveLeaderboardData,
    getLeaderboardData,
    getLeaderboardInfo,
  };
});
