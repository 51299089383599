import { LeaderboardApi } from './leaderboard.api';

const RANDOM_UUID = '3f29f9e8-8c4b-4a2f-9f1b-2d5e6f7a8b9c';

export const LeaderboardService = new (class {
  async getLeaderboard() {
    const response = await LeaderboardApi.getLeaderboard(
      RANDOM_UUID,
    );
    return response;
  }

  async getLeaderboardInfo() {
    const response = await LeaderboardApi.getLeaderboardInfo();
    return response;
  }
})();
